.picker-container {
  margin-bottom: 35px;
}

.picker-container .picker-highlight {
  display: none;
}

.picker-container .picker-column {
  width: 100%;
  max-width: 314px;
  margin: 0 auto;
}

.picker-container .picker-column .picker-item {
  font-family: 'Inter', 'Arial', sans-serif;
  font-weight: 300;
  line-height: 23px;
}

.picker-container .picker-column .picker-item.picker-item-selected {
  font-family: 'Inter', 'Arial', sans-serif;
  background-color: #f2f1f7;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 44px !important;
  letter-spacing: -0.26px;
  height: 44px !important;
  color: #323232 !important;
}

.picker-item {
  font-size: 14px;
}
.picker-item-selected + .picker-item {
  font-size: 15px !important;
}
.picker-item:has(+ .picker-item-selected) {
  font-size: 15px !important;
}

.picker-container div.picker-inner {
  padding: 0;
}
