@font-face {
  font-family: 'Inter';
  src: local('Inter Regular'), local('Inter-Regular'),
    url('../../fonts/Inter-Regular.woff2') format('woff2'),
    url('../../fonts/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Light'), local('Inter-Light'),
    url('../../fonts/Inter-Light.woff2') format('woff2'),
    url('../../fonts/Inter-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Medium'), local('Inter-Medium'),
    url('../../fonts/Inter-Medium.woff2') format('woff2'),
    url('../../fonts/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
