:root {
  --100vh-height: 100vh;
  --body-bg-color: '#fff';
}

html {
  scroll-behavior: smooth;
}

body {
  color: #323232;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 100%;
  margin: 0;
  background: var(--body-bg-color);
  min-height: var(--100vh-height);
  user-select: none;
}

.no-scroll {
  overflow: hidden;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}
