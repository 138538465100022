.datepicker__container {
  width: 100%;
  max-width: 314px;

  .datepicker-header {
    background-color: #ffffff;
  }

  .datepicker.ios {
    background-color: #ffffff;
    top: 20px;
    width: inherit;
  }

  .datepicker.ios .datepicker-navbar {
    display: none;
  }

  .datepicker .datepicker-content {
    padding: 0.5em 2.00em;
  }
  
  .datepicker .datepicker-viewport {
    height: 162px;
  }

  .datepicker.ios .datepicker-scroll li {
    color: #657786;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: -0.26px;
  }

  .datepicker.ios .datepicker-wheel {
    border-top: 1px solid #d2d8dd;
    border-bottom: 1px solid #d2d8dd;
  }
  .datepicker.ios .datepicker-viewport::after {
    background: none;
  }

  .datepicker.ios .datepicker-scroll li.disabled {
    display: none;
  }

  .datepicker.ios .datepicker-content {
    background: white;
    border: solid #d2d8dd 1px;
    border-radius: 20px;
    padding-top: 30px;
    padding-bottom: 0;
  }
}